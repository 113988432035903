<template>
  <template v-if="milestones && goal && startDate && endDate">
    <div class="chart-block">
        <div class="line-chart">
            <div class="line-item" v-for="mile in milestones" v-bind:key="mile">{{type}} {{mile}}</div>
        </div>
        <div class="chart-title">You have to write approximately <br>
            <b>{{wordsPerMonth}} words per month</b>, or just <b>{{wordsPerWeek}} words a week</b>,
            to have all {{milestones}} {{ type.toLowerCase() }} ready on time.</div>
        <Chart
        :wordsPerMonth="wordsPerMonth"
        :months="months"
        :chapters="milestones"
        :startDate="startDate"
        ></Chart>
        <div class="chart-bottom">
            <div class="date">{{startDateFormatNew}}</div>
            <div class="date">{{endDateFormatNew}}</div>
        </div>
    </div>
  </template>
  <template v-else>
    <p class="secondary-text">Please enter correct values</p>
  </template>
</template>

<script>
import Chart from './Chart.vue'
import moment from 'moment';
import {ref, reactive, computed, watch} from "vue";

const x = 2;

export default {
    name: "StatsComponent",
    props: {
        type: String,
        milestones: Number,
        goal: Number,
        startDate: String,
        endDate: String
    },
    components: {
        Chart
    },
    setup (props) {
      const wordsPerMonth = computed(() =>
        Math.round((props.type === 'Chapters'? props.goal : props.milestones*props.goal)/moment(props.endDate).diff(moment(props.startDate),'months'))
      )

      const wordsPerWeek = computed(() =>
          Math.round((props.type === 'Chapters'? props.goal : props.milestones*props.goal)/moment(props.endDate).diff(moment(props.startDate),'weeks'))
      )

      const startDateFormatNew = computed(() => moment(props.startDate).format('DD/MM/yyyy'))

      const endDateFormatNew = computed(() => moment(props.endDate).format('DD/MM/yyyy'))

      const months = computed(() => moment(props.endDate).diff(moment(props.startDate),'months'))

      return {
        wordsPerMonth, wordsPerWeek,
        startDateFormatNew, endDateFormatNew,
        months
      }
    }


}


</script>


<style scoped lang="sass">
*
    box-sizing: border-box
.chart-block
  padding: 25px 15px
  background-color: #FFFDF9
  border: 1px solid #F3EDDD
  border-radius: 8px
  font-family: Work Sans
  position: relative
  .chart-bottom
       display: flex
       justify-content: space-between
       font-weight: bold
       font-size: 12px
       line-height: 150%
       margin-top: 20px
  .chart-title
      width: 350px
      margin-left: 15px
      margin-bottom: 20px
      line-height: 150%
      font-size: 14px
  .line-chart
      position: absolute
      bottom: 52px
      left: 0px
      width: 100%
      display: flex
      justify-content: space-around
      padding-left: 35px
      padding-right: 15px
      font-size: 12px
</style>