<template>
  <NewBar
    :labels="newLabels"
    :datasets="newDatasets"
    :options="$options.options"
  ></NewBar>
</template>

<script>
import NewBar from './NewBar';
import moment from 'moment';

const yellow = '#FFDF8C';
const darkYellow = '#e3ad22';

const options = {
    legend: false,
    showXLabels: 3,
    scales: {
        xAxes: [{
            barPercentage: 1,
            categoryPercentage: 0.9,
            gridLines: {
                display: false,
                color: yellow,
                drawBorder: true               
            },
        ticks: {
            display: false
        }
        }],
        yAxes: [{
            id: 'y-axis',
            gridLines: {
                display: false,
                color: yellow,
                drawBorder: true   
            },
            ticks: {
                maxTicksLimit: 4,
                padding: -70,
                callback: function(value, index, values) {
                return (value == 0) ? '' : value + ' words';
            }
            }
        }],
    },
    tooltips: {
        enabled: false,

        custom: function(tooltipModel) {
            // Tooltip Element
            var tooltipEl = document.getElementById('chartjs-tooltip');

            // Create element on first render
            if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = '<table style="width: 100%; text-align: center"></table>';
                document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
            }

            // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
            tooltipEl.classList.add('no-transform');
        }

        function getBody(bodyItem) {
            return bodyItem.lines;
        }

        // Set Text
        if (tooltipModel.body) {
            var bodyLines = tooltipModel.title || [];
            var titleLines = tooltipModel.body.map(getBody);

            var innerHtml = '<thead>';

            titleLines.forEach(function(title) {
                innerHtml += '<tr><th>' + Math.round(title) + " words" + '</th></tr>';
            });
            innerHtml += '</thead><tbody>';

            bodyLines.forEach(function(body, i) {
                innerHtml += '<tr><td>' + body + '</td></tr>';
            });
            innerHtml += '</tbody>';

            var tableRoot = tooltipEl.querySelector('table');
            tableRoot.innerHTML = innerHtml;
        }

        // `this` will be the overall tooltip
        var position = this._chart.canvas.getBoundingClientRect();

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.backgroundColor = 'rgba(0,0,0,0.75)';
        tooltipEl.style.color = '#fff';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';        
        tooltipEl.style.textAlign = 'center';
        tooltipEl.style.borderRadius = '2px';
        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
        tooltipEl.style.pointerEvents = 'none';
    }
        
    }

}

export default {
  name: 'Chart',
  options,
  components: {
    NewBar
  }, 
  props: {
    wordsPerMonth: Number,
    months: Number,
    chapters: Number,
    startDate: String
  }, 
  computed: {
      newDatasets: function() {
        let currentMonthWords = 0;
        const dataArr = [];
        const backgroundArr = [];
        let changeColorMonths = 0;

        for (let i = 0; i < this.months; i++) {
          currentMonthWords+=this.wordsPerMonth
          dataArr.push(currentMonthWords);
          changeColorMonths = Math.round(i % (this.months/this.chapters*2));
          (changeColorMonths < this.months/this.chapters) ? backgroundArr.push(yellow) : backgroundArr.push(darkYellow);
        }

        return [{
          data: dataArr,
          backgroundColor: backgroundArr,
          hoverBackgroundColor: 'rgba(255, 195, 40, 0.7)'
        }];
      },
      newLabels: function() {
        const labelsArr = [];
        for (let i = 0; i < this.months; i++) {

          labelsArr.push(moment(this.startDate).add('months', i + 1).format('MMM, YYYY'));
          // labelsArr.push(`Dec ${i}, 2021`);
        }

        return labelsArr
      }
  }
}
</script>