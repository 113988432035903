import axios from "axios";

export async function saveRoadmapInfo(workspace, roadmapInfo) {
  const response = await axios.post("/roadmaps", {
    workspace,
    data: {
      ...roadmapInfo
    }
  });

  return response.data;
}

export async function getRoadmapInfo(workspace) {
  const response = await axios.get("/roadmaps", {
    params: {
      workspace
    }
  });

  return response.data;
}
