<template>
  <ul class="toggle uk-subnav uk-subnav-pill">
    <template v-for="(item, index) in items" :key="'toggle-item-' + item">
      <li
        class="toggle-item"
        :class="{ active: item === modelValue }"
        @click="setActive(item)"
      >
        {{ item }}
      </li>
    </template>
  </ul>
</template>

<script>
import { ref } from "vue";

export default {
  name: "Toggle",
  props: {
    modelValue: {
      type: String
    },
    items: {
      type: Array,
      required: true
    }
  },
  setup(props, { emit }) {
    const setActive = function(item) {
      emit("update:modelValue", item);
    };
    return {
      setActive
    };
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/color-scheme.scss";

.toggle {
  width: auto;
  background-color: $pxp-gray-accent-color-dimmer;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-items: center;
  flex-grow: 1;
  &-item {
    display: block;
    color: $pxp-blue-added-color;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    padding: 4px 16px;
    margin: 2px;
    height: 100%;
  }

  &-item.active {
    border-radius: 4px;
    background-color: $pxp-white-color;
    color: $pxp-black-color;
  }
}
</style>
