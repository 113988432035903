<template>
  <canvas id="chart"></canvas>
</template>

<script>
import Chart from 'chart.js';

export default {
  name: "NewBar",
  props: {
      labels: Array,
      datasets: Array,
      options: Object
  },
  data: () => ({
    chart: undefined
  }),
  watch: {
    // Watch for the datasets changes.
    datasets(newDatasets) {
      // Replace the datasets and call the update() method on Chart.js
      // instance to re-render the chart.
      this.chart.data.datasets = newDatasets;
      this.chart.update();
    },
    labels(newLabels) {
      // Replace the datasets and call the update() method on Chart.js
      // instance to re-render the chart.
      this.chart.data.labels = newLabels;
      this.chart.update();
    }
  },
  mounted () {

    this.chart = new Chart(document.querySelector("#chart"), {
      type: 'bar',
      data: {
        labels: this.labels,
        datasets: this.datasets
      },
      options: this.options
    });
  }
}
</script>