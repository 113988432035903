<template>
<div class="RoadmapLeftColumn">

    <div class="content-block">
        <h2>Milestones</h2>
       <h3>{{roadmapObj.texts[0]}}</h3>
       <div class="range-block">
           <Range v-model="modelValue.milestones.value" :min="1" :max="10" :step="1" @focusout="$emit('update-roadmap-data')"/>
           <div class="range-info">
               <span>{{modelValue.milestones.value}}</span> {{modelValue.type.value === "researchpapers"? "papers": modelValue.type.value.toLowerCase() }}
           </div>
       </div>
    </div>
    <div class="content-block">
        <h2>Goal</h2> 
       <h3>{{roadmapObj.texts[1]}}</h3>
       <div class="range-block">
           <Range v-model="modelValue.goal.value" :min="roadmapObj.range[0]" :max="roadmapObj.range[1]" :step="500" @focusout="$emit('update-roadmap-data')"/>
           <div class="range-info">
               <span>{{modelValue.goal.value}}</span> words
           </div>
       </div>
      <!--
       <p class="hint-text">
           You have to write approximately <br>
            <span>{{189*36}} words per chapter</span>
       </p>-->
    </div>
    <div class="content-block">
        <h2>Time</h2>
        <div class="date-blocks">
       <div class="date-block">
           <h3>Start date</h3>
           <TextInputNew v-model="modelValue.startDate.value" type="date" @focusout="$emit('update-roadmap-data')" />
       </div>
       <div class="date-block">
           <h3>End date</h3>
           <TextInputNew v-model="modelValue.endDate.value" type="date" @focusout="$emit('update-roadmap-data')"/>
       </div>
       </div>
       <p class="hint-text">
           It’s <span>{{months}} months</span> to complete your {{modelValue.type.value !== "researchpapers"? 'thesis': 'research papers'}}
       </p>
    </div>
       

       
       
       
</div>
</template>

<script>
import { getRoadmapInfo, saveRoadmapInfo } from "@/api/roadmap";

import {
  ref,
  toRefs,
  toRef,
  reactive, // (https://v3.vuejs.org/api/refs-api.html#ref)
  computed,
  watch, // (https://v3.vuejs.org/api/computed-watch-api.html)
  onMounted,
  onUnmounted // (https://learnvue.co/2020/03/how-to-use-lifecycle-hooks-in-vue3/)
} from "vue";

import TextInputNew from "@/components/UI-Kit/TextInputNew";
import Range from "@/components/UI-Kit/Range";
import Toggle from "@/components/UI-Kit/Toggle";
import moment from 'moment';


export default {
    name: "RoadmapLeftColumn",
    components: {
    TextInputNew,
    Range,
    Toggle
  },
  props: {
    roadmapObj: Object,
    modelValue: Object
  },
  setup(props) {
    const months = computed(() => moment(props.modelValue.endDate.value).diff(moment(props.modelValue.startDate.value),'months'))

    return {
        months
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/color-scheme.scss";


.content-block {
    margin-bottom: 30px;
}

h3 {
    margin-bottom: 16px;
    color: #000;
}

h2 {
    margin-bottom: 8px;
}

.range-block {
    width: 70%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
}

.range-info span {
    color: $pxp-green-color;
}

.date-blocks {
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
    margin-bottom: 16px;
}

.date-blocks h3 {
    margin-bottom: 8px;
}

p.hint-text span {
    color: $pxp-gray-color-darken;
}


</style>


